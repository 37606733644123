import ActivityLogMain from "../ActivityLogMain";
import ActivityLogPeopleFilterDropdown from "../ActivityLogPeopleFilterDropdown";
import ActivityLogActionFilterDropdown from "../ActivityLogActionFilterDropdown";
import { FaSearch } from "react-icons/fa";
import MiniCalendarDropdown from "../MiniCalendarDropdown";
import { useSchedulerActivityLog } from "../../contexts/SchedulerActivityLog";
import PaginationFooterBar from "../PaginationFooterBar";
import SchedulerLocationDropdown from "../SchedulerLocationDropdown";
import { useAuth } from "../../contexts/auth";
import { useScheduler } from "../../contexts/scheduler";
import { useResponsive } from "../../contexts/responsive";
import cookies from 'browser-cookies';

const ActivityLogPage = () => {
  const {
    selectedPeople,
    setSelectedPeople,
    peopleOptions,
    selectedActions,
    setSelectedActions,
    actionOptions,
    searchTerm,
    setSearchTerm,
    selectedDays,
    setSelectedDays,
    displayItems,
    allItems,
    currentPage,
    setCurrentPage,
    RESULTS_PER_PAGE,
  } = useSchedulerActivityLog();
  const { me } = useAuth();
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler();
  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className="w-full pb-64 overflow-y-scroll bg-white bg-navy-bright flex flex-col justify-start items-center">
      <div
        className={`bg-white rounded-2xl w-full md:w-5/6 xl:w-2/3 md:mr-5 my-6 flex flex-col border border-navy-light `}
      >
        <div className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-30 bg-navy-bright">
          <h1 className="text-xl font-semibold text-dark-blue">Activity log</h1>
          {!isMobileView && me?.scheduler?.length > 1 && (
            <SchedulerLocationDropdown
              value={selectedScheduler?.location?.id}
              placeholder={"Schedule Location:"}
              options={locationDropdownOptions}
              onChange={(e) => {
                const selectedLocationId = parseInt(e.target.value);
                const matchingScheduler = me?.scheduler?.find(
                  (item) => item.location.id === selectedLocationId
                );
                if (matchingScheduler) {
                  cookies.set("schedulerId", matchingScheduler.id.toString());
                  setSelectedScheduler(matchingScheduler);
                }
              }}
              role='scheduler'
            />
          )}
        </div>
        <div className="h-16 flex justify-between w-full items-center px-6 bg-opacity-10 bg-navy-bright border-b border-navy-light relative">
          <div className="flex flex-row">
            <MiniCalendarDropdown
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
            <ActivityLogPeopleFilterDropdown
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
              peopleOptions={peopleOptions}
            />
            <ActivityLogActionFilterDropdown
              selectedActions={selectedActions}
              setSelectedActions={setSelectedActions}
              actionOptions={actionOptions}
            />
          </div>
          <div className="flex bg-white rounded-lg flex-option items-center border border-navy-light overflow-hidden">
            <FaSearch className="ml-3 text-gray-blue w-[16px]" />
            <input
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder="Search"
              type="text"
              className="p-[4px] md:p-[6px] flex-1 text-dark-blue border-none focus:ring-0"
            />
          </div>
        </div>
        <ActivityLogMain displayItems={displayItems} />

        <PaginationFooterBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItems={allItems}
          resultsPerPage={RESULTS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default ActivityLogPage;
