import { useContext } from "react";
import DropdownCheckboxGeneral from "../../components/DropdownCheckboxGeneral";
import SchedulerLocationDropdown from "../SchedulerLocationDropdown";
import { useResponsive } from "../../contexts/responsive";
import { useAuth } from "../../contexts/auth";
import { useScheduler } from "../../contexts/scheduler";
import cookies from 'browser-cookies';


const columnDetails = [
  {
    title: 'Intake',
    id: 'Intake',
  },
  {
    title: 'Backlog',
    id: 'Backlog',
  },
  {
    title: 'Ready for calendar',
    id: 'Ready for calendar',
  },
  {
    title: 'Scheduled',
    id: 'Scheduled',
  },
  {
    title: 'Completed',
    id: 'Completed',
  },
  {
    title: 'Archive',
    id: 'Archive',
  },
]

const JobsListHeaderBar = ({jobType, setJobType, selectedStatuses, setSelectedStatuses, columnHeaders}) => {
  const { me } = useAuth();
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler();
  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  return (
    <div className="flex justify-between w-full h-12 items-center px-6 bg-opacity-10 bg-navy-bright border-b border-navy-light">
      <h1 className="text-lg text-dark-blue font-semibold">Job List</h1>
      <div className="flex flex-row justify-center items-center">
        {!isMobileView && me.scheduler.length > 1 &&
          <SchedulerLocationDropdown
            value={selectedScheduler?.location?.id}
            placeholder={'Schedule Location:'}
            options={locationDropdownOptions}
            onChange={(e) => {
              const selectedLocationId = parseInt(e.target.value);
              const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
              if (matchingScheduler) {
                cookies.set('schedulerId', matchingScheduler.id.toString());
                setSelectedScheduler(matchingScheduler);
              }
            }}
            role='scheduler'
          />
        }
        <div className='lg:ml-[12px]'>
          <DropdownCheckboxGeneral
            columnDetails={columnDetails}
            title={'Filter status'}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
          />
        </div>
      </div>
    </div>
  )
};

export default JobsListHeaderBar;
