import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  startOfToday,
  startOfWeek,
  eachDayOfInterval,
  endOfWeek,
  endOfMonth,
  startOfMonth,
  addDays,
  addMonths,
} from 'date-fns'
import { useScheduler } from "./scheduler";
import service from "../service";
import { useAuth } from "./auth";

const SchedulerCalendarContext = createContext()

const SchedulerCalendarContextProvider = props => {
  const { me, setMe } = useAuth()
  const [interval, setInterval] = useState('week')
  const [displayMode, setDisplayMode] = useState('vertical')
  const [isDragging, setIsDragging] = useState(false) //used for styles?
  const [calendarJobs, setCalendarJobs] = useState([])
  const [flashingJobs, setFlashingJobs] = useState([])
  const isCtrlKeyPressed = useRef(false)
  const { selectedScheduler } = useScheduler()
  const { location: { jobs: allJobs } } = selectedScheduler
  const newCalendarJobs = useRef([])
  const newFlashingJobs = useRef([])
  const { pathname } = useLocation();

  const draggedFrom = useRef('')
  const draggedTo = useRef('')
  const draggedJob = useRef({})

  let dropZone = document.getElementById('dropZone')
  let fDropZone = document.getElementById('fDropZone')

  function handleDragStart(e) { //applies to job cards
    e.dataTransfer.effectAllowed = 'all'  //maybe not necessary?
    setIsDragging(true)

    draggedFrom.current = e.target.parentNode.dataset.timeslotdata ? JSON.parse(e.target.parentNode.dataset.timeslotdata) : e.target.parentNode.dataset.jobsidepane
    draggedJob.current = JSON.parse(e.target.dataset.job) //the job being dragged as an object, works for both JFJobCards and CalendarJobCards
    // styles applied to the job card that stays in the original position
    this.setAttribute('id', 'dragging')
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    if (draggedJob.current.calendarJobType !== 'REGULAR') return
    if (!dropZone) {
      dropZone = document.getElementById('dropZone')
    }
    dropZone.removeAttribute('class', 'hidden')
    const droppable = e.target.closest('.droppable')
    droppable.appendChild(dropZone)
    if (droppable.dataset.jobsidepane) {
      dropZone.classList.add('h-[80px]', 'flex', 'items-center', 'justify-center', 'border-2', 'border-dashed')
    }
    droppable.classList.add('border-2')
    droppable.classList.remove('bg-opacity-30')
    droppable.classList.add('bg-opacity-60')
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    const droppable = e.target.closest('.droppable')
    droppable.classList.remove('border-2')
    droppable.classList.remove('bg-opacity-60')
    droppable.classList.add('bg-opacity-30')
  }

  async function handleDrop(e) {
    e.preventDefault()
    const droppable = e.target.closest('.droppable') //finds the nearest parent with droppable class
    draggedTo.current = droppable.dataset.timeslotdata ? JSON.parse(droppable.dataset.timeslotdata) : droppable.dataset.jobsidepane
  }

  function handleDragEnd(e) {//applies to job cards
    this.setAttribute('id', '')
    setIsDragging(false)
    postDragHandling(e)
  }

  const postDragHandling = async (e) => {//applies to job cards
    //always hide the dropZone; do this before returning the function
    if (!dropZone) {
      dropZone = document.getElementById('dropZone')
    }
    dropZone.classList.add('hidden')
    document.getElementById('calendarBody').appendChild(dropZone)

    //if a user finishes the drag in some place that is not a valid drop zone, finish executing the function without doing anything
    if (!draggedTo.current.shift && draggedTo.current !== 'jobSidePane') return

    // dragging job back into side pane / 'ready'
    if (draggedTo.current === 'jobSidePane') {

      const { me } = await service.markCalendarJobDeleted([draggedJob.current.id], draggedJob.current.calendarJobType)

      setMe(me)

      // determine the number of REGULAR calendarJobs still on the calendar
      let numberOfCalJobs = 0
      newCalendarJobs.current.forEach((job) => {
        if (draggedJob.current.jobId === job.jobId && job.calendarJobType === 'REGULAR') {
          numberOfCalJobs++
        }
      })

      // only updateDate if this is the last REGULAR calendarJob on the calendar
      if (numberOfCalJobs === 1) {
        const { me } = await service.updateDate(draggedJob.current.jobId, 'ready')
        setMe(me)
      }

    } else if (draggedFrom.current !== 'jobSidePane' && draggedTo.current !== 'jobSidePane') {
      if (isCtrlKeyPressed.current) {
        //if the user holds ctrl and drags, a new calendar job is created alongside the original showing in the new timeslot or crew
        const calendarJobDetails = {
          jobId: draggedJob.current.jobId,
          crewId: draggedTo.current.crewId,
          scheduledDate: draggedTo.current.scheduledDate,
          shift: draggedTo.current.shift
        }
        const { newCalendarJob } = await service.createCalendarJobFromCal(calendarJobDetails)
        const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
        const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === newCalendarJob.jobId)
        
        setMe({
          ...me,
          scheduler: [
            ...me.scheduler.slice(0, selectedSchedulerIndex),
            {
              ...selectedScheduler,
              location: {
                ...selectedScheduler.location,
                jobs: [
                  ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                  {
                    ...selectedScheduler.location.jobs[selectedJobIndex],
                    calendarJobs: [
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs,
                      newCalendarJob,
                    ]
                  },
                  ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
                ]
              }
            },
            ...me.scheduler.slice(selectedSchedulerIndex + 1)
          ]
        })
      
      } else {
        // job moved around on calendar, update the calendarJob
        const calendarJobDetails = {
          // info to update calendarJob
          calendarJobId: draggedJob.current.id,
          newScheduledDate: draggedTo.current.scheduledDate,
          shift: draggedTo.current.shift,

          // info to update calendarJobCrew
          calendarJobCrewId: draggedJob.current.crews[0].id,
          newCrewId: draggedTo.current.crewId
        }
        const { updatedCalendarJob } = await service.updateCalendarJob(calendarJobDetails)
        const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
        const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedCalendarJob.jobId)
        const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedCalendarJob.id)
        const newMe = {
          ...me,
          scheduler: [
            ...me.scheduler.slice(0, selectedSchedulerIndex),
            {
              ...selectedScheduler,
              location: {
                ...selectedScheduler.location,
                jobs: [
                  ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                  {
                    ...selectedScheduler.location.jobs[selectedJobIndex],
                    calendarJobs: [
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
                      updatedCalendarJob,
                      ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
                    ]
                  },
                  ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
                ]
              }
            },
            ...me.scheduler.slice(selectedSchedulerIndex + 1)
          ]
        }
        setMe(newMe)
      }

    } else if (draggedFrom.current === 'jobSidePane') {
      // job dragged from sidePane to calendar, create calendarJob

      const calendarJobDetails = {
        jobId: draggedJob.current.id,
        crewId: draggedTo.current.crewId,
        scheduledDate: draggedTo.current.scheduledDate,
        shift: draggedTo.current.shift,
        updateDate: true,
      }

      const { newCalendarJob } = await service.createCalendarJobFromCal(calendarJobDetails)
      const selectedSchedulerIndex = me.scheduler.findIndex(scheduler => scheduler.id === selectedScheduler.id)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === newCalendarJob.jobId)
      
      setMe({
        ...me,
        scheduler: [
          ...me.scheduler.slice(0, selectedSchedulerIndex),
          {
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                {
                  ...selectedScheduler.location.jobs[selectedJobIndex],
                  calendarJobs: [
                    newCalendarJob
                  ],
                  //Put in a simple truthy value for scheduledAt.  The important thing is that the value is not null.  If scheduledAt === null, it will remain in the job-flashes pane
                  scheduledAt: true
                },
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          },
          ...me.scheduler.slice(selectedSchedulerIndex + 1)
        ]
      })
    }


    draggedFrom.current = ''
    draggedTo.current = ''
    draggedJob.current = {}

  }

  const handleDragStartFlash = (e) => { //applies to job cards
    e.dataTransfer.effectAllowed = 'all'  //maybe not necessary?

    draggedFrom.current = e.target.parentNode.dataset.timeslotdata ? JSON.parse(e.target.parentNode.dataset.timeslotdata) : e.target.parentNode.dataset.jobsidepane
    draggedJob.current = JSON.parse(e.target.dataset.job) //the job being dragged as an object, works for both JFJobCards and CalendarJobCards
    // styles applied to the job card that stays in the original position
    // this.setAttribute('id', 'dragging')
  }

  const handleDragOverFlash = (e) => {
    e.preventDefault()
    if (draggedJob.current.calendarJobType !== 'FLASHING') return
    if (!fDropZone) {
      fDropZone = document.getElementById('fDropZone')
    }
    fDropZone.setAttribute('class', 'flex justify-center items-center bg-opacity-60 border-dashed border-2')
    const FDroppable = e.target.closest('.FDroppable')
    FDroppable.appendChild(fDropZone)
    FDroppable.classList.add('border-2')
    FDroppable.classList.remove('bg-opacity-30')
    FDroppable.classList.add('bg-opacity-60')
  }

  const handleDragLeaveFlash = (e) => {
    e.preventDefault()
    const FDroppable = e.target.closest('.FDroppable')
    FDroppable.classList.remove('border-2')
    FDroppable.classList.remove('bg-opacity-60')
    FDroppable.classList.add('bg-opacity-30')
  }

  const handleDropFlash = async (e) => {
    e.preventDefault()
    const FDroppable = e.target.closest('.FDroppable')
    draggedTo.current = FDroppable.dataset.timeslotdata ? JSON.parse(FDroppable.dataset.timeslotdata) : FDroppable.dataset.jobsidepane
    FDroppable.classList.remove('border-2')
    FDroppable.classList.remove('bg-opacity-60')
    FDroppable.classList.add('bg-opacity-30')
  }

  const handleDragEndFlash = async (e) => {//applies to job cards
    e.preventDefault()
    postDragHandlingFlash(e)
  }

  const postDragHandlingFlash = async (e) => {//applies to job cards

    if (!fDropZone) {
      fDropZone = document.getElementById('fDropZone')
    }
    document.getElementById('calendarBody').appendChild(fDropZone)
    fDropZone.setAttribute('class', 'hidden')
    //if a user finishes the drag in some place that is not a valid drop zone, finish executing the function without doing anything
    if (!draggedTo.current.fshift && draggedTo.current !== 'jobSidePane') return //only proceed if dropped onto a flash droppable or the job side pane (thus, do nothing if dropped into empty space or a regular job timeslot)

    if (draggedTo.current === 'jobSidePane') {
      const { me } = await service.unscheduleFlashJobFromCal(draggedJob.current.id)
      setMe(me)
    } else if (draggedFrom.current !== 'jobSidePane' && draggedTo.current !== 'jobSidePane') {
      // job moved around on calendar, update the calendarJob
      const calendarJobDetails = {
        // info to update calendarJob
        calendarJobId: draggedJob.current.id,
        newScheduledDate: draggedTo.current.scheduledDate,
        fshift: draggedTo.current.fshift,

        // info to update calendarJobCrew
        calendarJobCrewId: draggedJob.current.crews[0].id,
        newCrewId: draggedTo.current.crewId
      }
      const { me } = await service.rescheduleFlashJobOnCal(calendarJobDetails)
      setMe(me)
    } else if (draggedFrom.current === 'jobSidePane') {
      // job dragged from sidePane to calendar, create calendarJob
      const calendarJobDetails = {
        calendarJobId: draggedJob.current.id,
        crewId: draggedTo.current.crewId,
        scheduledDate: draggedTo.current.scheduledDate,
        fshift: draggedTo.current.fshift
      }

      const { me } = await service.scheduleFlashJobOnCal(calendarJobDetails)
      setMe(me)
    }

    draggedFrom.current = ''
    draggedTo.current = ''
    draggedJob.current = {}
  }

  useEffect(() => {
    const droppables = document.querySelectorAll('.droppable')

    for (const droppable of droppables) {
      droppable.addEventListener('dragover', handleDragOver)
      droppable.addEventListener('dragleave', handleDragLeave)
      droppable.addEventListener('drop', handleDrop)
    }

    const FDroppables = document.querySelectorAll('.FDroppable')

    for (const FDroppable of FDroppables) {
      FDroppable.addEventListener('dragover', handleDragOverFlash)
      FDroppable.addEventListener('dragleave', handleDragLeaveFlash)
      FDroppable.addEventListener('drop', handleDropFlash)
    }

    return () => {
      for (const droppable of droppables) {
        droppable.removeEventListener('dragover', handleDragOver)
        droppable.removeEventListener('dragLeave', handleDragLeave)
        droppable.removeEventListener('drop', handleDrop)
      }

      for (const FDroppable of FDroppables) {
        FDroppable.removeEventListener('dragover', handleDragOverFlash)
        FDroppable.removeEventListener('dragleave', handleDragLeaveFlash)
        FDroppable.removeEventListener('drop', handleDropFlash)
      }
    }

  }, [pathname, calendarJobs, interval, displayMode])

  //setting calendarJobs and flash jobs
  useEffect(() => {
    newCalendarJobs.current = []
    newFlashingJobs.current = []
    allJobs.forEach((job) => {
      if (job.calendarJobs[0] && !job.archivedAt) {
        //add all the calJobs for a given job
        newCalendarJobs.current.push(...job.calendarJobs)
      }
    })
    setCalendarJobs(newCalendarJobs.current)
    newFlashingJobs.current = newCalendarJobs.current.filter((job) => job.calendarJobType === 'FLASHING')
    setFlashingJobs(newFlashingJobs.current)
  }, [allJobs])

  useEffect(() => {
    const handleCtrlKeyChange = (e) => {
      isCtrlKeyPressed.current = e.ctrlKey

    }
    document.body.addEventListener('keydown', handleCtrlKeyChange)
    document.body.addEventListener('keyup', handleCtrlKeyChange)
    return () => {
      document.body.removeEventListener('keydown', handleCtrlKeyChange)
      document.body.removeEventListener('keyup', handleCtrlKeyChange)

    }
  }, [])

  //calendar variables and logic below:
  const today = startOfToday() //date object
  const [currentDay, setCurrentDay] = useState(today) //date object


  const setDays = () => {
    if (interval === 'week') {
      return eachDayOfInterval({
        start: startOfWeek(currentDay),
        end: endOfWeek(currentDay)
      })
    }
    if (interval === 'month') {
      return eachDayOfInterval({
        start: startOfMonth(currentDay),
        end: endOfMonth(currentDay)
      })
    }
  }

  let days = setDays() //array of date objects running through the current week or the current month

  const filteredDays = days.filter((day) => {
    return day.getDay() !== 0
  }) //filteredDays is an array of days without Sundays, used for the monthly views.

  const previousWeek = () => {
    setCurrentDay(addDays(currentDay, -7))
  }

  const nextWeek = () => {
    setCurrentDay(addDays(currentDay, 7))
  }

  const previousMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const nextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }



  return (
    <SchedulerCalendarContext.Provider value={{
      interval, setInterval,
      displayMode, setDisplayMode,
      today, currentDay, setCurrentDay,
      days, filteredDays,
      previousWeek, nextWeek,
      previousMonth, nextMonth,
      calendarJobs, flashingJobs,
      handleDragStart, handleDragEnd,
      handleDrop, isDragging,
      handleDragStartFlash, handleDragEndFlash
    }}>
      {props.children}
    </SchedulerCalendarContext.Provider>
  )
}

const useSchedulerCalendar = () => useContext(SchedulerCalendarContext)
export { useSchedulerCalendar }
export default SchedulerCalendarContextProvider
