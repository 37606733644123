import cookies from 'browser-cookies';

const dev = 'http://localhost:3030/v1'
const prod = `${process.env.REACT_APP_API_URL}/v1`
const DOMAIN = (window.location.host.includes('localhost')) ? dev : prod
const COOKIE_HOST = window.location.hostname.includes('usgmanage') ? '.usgmanage.com' : 'localhost'

const serviceMethods = {
  login: async (email, password) => {
    const res = await fetch(
      uri_login,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    return res
  },
  loginWithPin: async (hash, pin) => {
    const res = await fetch(
      uri_login_with_pin,
      {
        method: 'POST',
        body: JSON.stringify({
          hash,
          pin,
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    return res
  },
  newCustomer: async (builderInfo) => {
    const res = await fetch(
      uri_customers,
      {
        method: 'POST',
        body: JSON.stringify(builderInfo),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getPathway: async () => {
    const res = await fetch(
      uri_pathway,
      {
        method: 'GET',
        body: null,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  setAuthTokens: (tokens) => {
    const { access, refresh } = tokens
    cookies.set('at', access.token, { expires: access.expires })
    cookies.set('rt', refresh.token, { expires: refresh.expires })
  },
  getAuthTokens: () => {
    const accessToken = cookies.get('at')
    const refreshToken = cookies.get('rt')
    return {
      accessToken,
      refreshToken,
    }
  },
  clearAllCookies: () => {
    cookies.erase('at')
    cookies.erase('rt')
    cookies.erase('intakeFlowId')
    cookies.erase('guniteMixType')
    cookies.erase('searchZip')
    cookies.erase('branchId')
    cookies.erase('currentPosition')
    cookies.erase('currentService')
    cookies.erase('schedulerId')
    cookies.erase('foremanId')
  },
  clearAuthTokens: () => {
    cookies.erase('at')
    cookies.erase('rt')
    cookies.erase('schedulerId')
    cookies.erase('foremanId')
  },
  getProfile: async () => {
    const res = await _protectedFetch(uri_profile)
    return res
  },
  setLocationCookies: (zipCode, closestBranch) => {
    cookies.set('branchId', `${closestBranch?.id}`, { expires: 400 });
    cookies.set('searchZip', `${zipCode}`, { expires: 400 });
  },
  getLocationCookies: () => {
    const branchId = cookies.get('branchId')
    const searchZip = cookies.get('searchZip')
    return {
      branchId,
      searchZip,
    }
  },
  createGuniteJob3a: async (jobDetails) => {
    const res = await fetch(
      uri_create_gunite_job_3a,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createPlasterJob3a: async (jobDetails) => {
    const res = await fetch(
      uri_create_plaster_job_3a,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getLocationSpecificJobs: async (locationId) => {
    const url = `${uri_get_location_specific_jobs}?locationId=${locationId}`;
    const res = await fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getAllJobs: async () => {
    const res = await fetch(
      uri_get_all_jobs,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  setIntakeFlowIdCookie: async (intakeFlowId) => {
    cookies.set('intakeFlowId', JSON.stringify(intakeFlowId), { expires: 400 });
  },
  setGuniteMixTypeCookie: async (guniteMixType) => {
    cookies.set('guniteMixType', guniteMixType)
  },
  updateGuniteJob3a: async (jobDetails) => {
    const res = await fetch(
      uri_update_gunite_job_3a,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePlasterJob3a: async (jobDetails) => {
    const res = await fetch(
      uri_update_plaster_job_3a,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePoolsPlaster3b: async (poolDetailsArray) => {
    const res = await fetch(
      uri_update_pools_plaster_3b,
      {
        method: 'POST',
        body: JSON.stringify(poolDetailsArray),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePoolsGunite3b: async (poolDetailsArray) => {
    const res = await fetch(
      uri_update_pools_gunite_3b,
      {
        method: 'POST',
        body: JSON.stringify(poolDetailsArray),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePoolPlasterChoices: async (poolDetails) => {
    const res = await fetch(
      uri_update_pool_plaster_choices,
      {
        method: 'POST',
        body: JSON.stringify(poolDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePlaster: async (jobDetails) => {
    const res = await fetch(
      uri_update_plaster,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updateSupervisor: async (jobDetails) => {
    const res = await fetch(
      uri_update_supervisor,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updateRequestedDate: async (jobDetails) => {
    const res = await fetch(
      uri_update_requested_date,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  uploadPoolPlan: async (poolPlan) => {
    const res = await fetch(
      uri_upload_pool_plan,
      {
        method: 'POST',
        body: poolPlan,
      }
    )
    return res
  },
  updatePoolPlan: async (poolPlan) => {
    const res = await fetch(
      uri_update_pool_plan,
      {
        method: 'POST',
        body: poolPlan,
      }
    )
    return res
  },
  uploadLocationFile: async (locationFile) => {
    const res = await fetch(
      uri_upload_location_file,
      {
        method: 'POST',
        body: locationFile,
      }
    )
    return res
  },
  deleteLocationFiles: async (locationFileUris) => {
    const res = await fetch(
      uri_delete_location_files,
      {
        method: 'POST',
        body: JSON.stringify(locationFileUris),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  deletePoolAndJobById: async (poolId, jobId) => {
    const res = await fetch(
      uri_delete_pool_and_job_by_id,
      {
        method: 'POST',
        body: JSON.stringify({ poolId: poolId, jobId: jobId }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updateJobAndPool: async ({ jobId, jobDetails, poolDetails, customerDetails }) => {
    const res = await fetch(uri_update_job_and_pool,
      {
        method: 'POST',
        body: JSON.stringify({
          jobId: jobId, jobDetails: jobDetails, poolDetails: poolDetails,
          customerDetails: customerDetails
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  submitJob: async (data) => {
    const res = await fetch(uri_submit_job,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  getDisabledDates: async () => {
    const res = await fetch(uri_get_disabled_dates,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  updateLocation: async (locationDetails) => {
    const res = await fetch(uri_update_location,
      {
        method: 'POST',
        body: JSON.stringify(locationDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  createLocation: async (locationDetails) => {
    const res = await fetch(uri_create_location,
      {
        method: 'POST',
        body: locationDetails,
      }
    )
    return res;
  },
  updateAdminLocationDetails: async (selectedLocation) => {
    const res = await _protectedFetch(uri_update_admin_branch_location_details,
      {
        method: 'POST',
        body: selectedLocation,
      }
    )
    return res;
  },
  updateJobLocation: async (data) => {
    const res = await fetch(uri_update_job_location,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  getLocations: async () => {
    const res = await fetch(uri_get_locations,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  getLocationsForMap: async () => {
    const res = await fetch(uri_get_locations_for_map,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  getLocationDetails: async (locationId) => {
    const url = `${uri_get_location_details}?id=${locationId}`
    const res = await _protectedFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  deleteLocations: async (locationIds) => {
    const res = await fetch(uri_delete_locations,
      {
        method: 'DELETE',
        body: JSON.stringify({locationIds}),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  getMaterials: async () => {
    const res = await fetch(uri_get_materials,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  addMaterialsSubcategoryItem: async (data) => {
    const res = await fetch(uri_add_materials_subcategory_item,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  updateMaterialsSubcategoryItem: async (data) => {
    const res = await fetch(uri_update_materials_subcategory_item,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  deleteMaterialsSubcategoryItems: async (data) => {
    const res = await fetch(uri_delete_materials_subcategory_items,
      {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  upsertLocationMaterialsSubcategoryItem: async (data) => {
    const res = await fetch(uri_upsert_location_materials_subcategory_item,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  upsertCheckboxChange: async (data) => {
    const res = await fetch(uri_upsert_checkbox_change,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  getLocationMaterials: async (locationId) => {
    const url = `${uri_get_location_materials}?locationId=${locationId}`
    const res = await _protectedFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  readRate: async () => {
    const res = await _protectedFetch(uri_read_rate, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  readLocationRate: async (locationId) => {
    const url = `${uri_read_location_rate}?locationId=${locationId}`
    const res = await _protectedFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  createRate: async (data) => {
    const res = await _protectedFetch(uri_create_rate, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  createLocationRate: async (data) => {
    const res = await _protectedFetch(uri_create_location_rate, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res;
  },
  updateCustomerAccountInfo: async (customerAccountPageDetails) => {
    const res = await fetch(uri_update_customer_account_info,
      {
        method: 'POST',
        body: JSON.stringify(customerAccountPageDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res;
  },
  requestPasswordResetEmail: async (email) => {
    const res = await fetch(uri_request_password_reset_email,
      {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updatePassword: async (token, newPassword) => {
    let newURL = uri_update_password + `/?token=${token}`
    const res = await fetch(newURL,
      {
        method: 'POST',
        body: JSON.stringify({ password: newPassword }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  verifyToken: async (token) => {
    let newURL = uri_verify_token + `/?token=${token}`
    const res = await fetch(newURL,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createCalendarJobFromCal: async (jobDetails) => {
    const res = await _protectedFetch(uri_create_calendar_job_from_cal,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  createCalendarJobFromMiniCal: async (jobDetails) => {
    const res = await _protectedFetch(uri_create_calendar_job_from_mini_cal,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  markCalendarJobDeleted: async (ids) => {
    const res = await _protectedFetch(uri_mark_calendar_job_deleted,
      {
        method: 'POST',
        body: JSON.stringify({
          ids
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  updateCalendarJob: async (jobDetails) => {
    const res = await _protectedFetch(uri_update_calendar_job,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  rescheduleCalendarJobFromMiniCal: async (rescheduleDetails) => {
    const res = await _protectedFetch(uri_reschedule_calendar_job_from_mini_cal,
      {
        method: 'POST',
        body: JSON.stringify(rescheduleDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  updateDate: async (id, fieldToUpdate) => {
    const res = await _protectedFetch(uri_update_date,
      {
        method: 'POST',
        body: JSON.stringify({
          id: id,
          target: fieldToUpdate
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  unarchive: async (id) => {
    const res = await _protectedFetch(uri_unarchive,
      {
        method: 'POST',
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  getJobNotes: async (jobId) => {
    const res = await _protectedFetch(uri_get_job_notes,
      {
        method: 'POST',
        body: JSON.stringify({
          jobId: jobId,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  pinOrUnpinNote: async (note) => {
    const res = await _protectedFetch(uri_pin_or_unpin_note,
      {
        method: 'POST',
        body: JSON.stringify({
          id: note.id,
          pinnedAt: note.pinnedAt,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createJobNote: async (note) => {
    const res = await _protectedFetch(uri_create_job_note,
      {
        method: 'POST',
        body: note
      }
    )
    return res
  },
  updateJobNote: async (note) => {
    const res = await _protectedFetch(uri_update_job_note,
      {
        method: 'POST',
        body: JSON.stringify({
          id: note.id,
          body: note.body
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  deleteJobNote: async (note) => {
    const res = await _protectedFetch(uri_delete_job_note,
      {
        method: 'POST',
        body: JSON.stringify({
          id: note.id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getAllAdmins: async () => {
    const res = await _protectedFetch(uri_get_all_admins,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createAdmin: async (newAdmin) => {
    const res = await _protectedFetch(uri_create_new_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          name: newAdmin.name,
          email: newAdmin.email
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updateAdmin: async (updatedAdmin) => {
    const res = await _protectedFetch(uri_edit_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          id: updatedAdmin.id,
          name: updatedAdmin.name,
          email: updatedAdmin.email
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  deleteAdmin: async (admin) => {
    const res = await _protectedFetch(uri_delete_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          id: admin.id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getAllBranchAdmins: async () => {
    const res = await _protectedFetch(uri_get_all_branch_admins,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createBranchAdmin: async (newAdmin) => {
    const res = await _protectedFetch(uri_create_new_branch_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          name: newAdmin.name,
          email: newAdmin.email,
          locationId: newAdmin.locationId
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  updateBranchAdmin: async (updatedAdmin) => {
    const res = await _protectedFetch(uri_edit_branch_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          id: updatedAdmin.id,
          name: updatedAdmin.name,
          email: updatedAdmin.email
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  deleteBranchAdmin: async (admin) => {
    const res = await _protectedFetch(uri_delete_branch_admin,
      {
        method: 'POST',
        body: JSON.stringify({
          id: admin.id,
          locationId: admin.locationId
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createFlashingJob: async (jobId) => {
    const res = await _protectedFetch(uri_create_flashing_job,
      {
        method: 'POST',
        body: JSON.stringify({ jobId: jobId }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  addActivityLog: async (activityLog) => {
    /* current activity log options:
    "intake"
    "backlog"
    "ready"
    "scheduled"
    "rescheduled"
    "flashingScheduled"
    "guniteCompleted"
    "plasterCompleted"
    "completed" (cost breakdown submitted)
    "workCompleted" (job marked as completed)
    "archive"
    "unarchive"
    "poolPlanUploaded"
    "assignedCrew"
    "assignedDrivers"
    "jobDetailsEdited"
    "jobTransferred"
    "dailyJobLogSubmitted"
    "costBreakdownSubmitted"
    */

    const tempDate = new Date();
    const [hour, minute, second] = tempDate.toLocaleTimeString('en-GB').split(':') //example: turns 2:09:21 PM into ['14', '09', '21']
    tempDate.setUTCHours(Number(hour), Number(minute), Number(second))

    activityLog.activityDateTime = tempDate //a date object

    const res = await _protectedFetch(uri_add_activity_log,
      {
        method: 'POST',
        body: JSON.stringify(activityLog),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  scheduleFlashJobOnCal: async (calendarJobDetails) => {
    const res = await _protectedFetch(uri_schedule_flash_job_on_cal,
      {
        method: 'POST',
        body: JSON.stringify(calendarJobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  unscheduleFlashJobFromCal: async (id) => {
    const res = await _protectedFetch(uri_unschedule_flash_job_from_cal,
      {
        method: 'POST',
        body: JSON.stringify({ id: id }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  rescheduleFlashJobOnCal: async (calendarJobDetails) => {
    const res = await _protectedFetch(uri_reschedule_flash_job_on_cal,
      {
        method: 'POST',
        body: JSON.stringify(calendarJobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  uploadPhotosToJobLog: async (formData) => {
    const res = await _protectedFetch(uri_upload_photos_to_job_log,
      {
        method: 'POST',
        body: formData
      }
    )
    const data = await res.json()
    return data
  },
  submitJobLogForeman: async (formDetails) => {
    const res = await _protectedFetch(uri_submit_job_log_foreman,
      {
        method: 'POST',
        body: JSON.stringify(formDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  submitJobLogScheduler: async (formDetails) => {
    const res = await _protectedFetch(uri_submit_job_log_scheduler,
      {
        method: 'POST',
        body: JSON.stringify(formDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  createNewJobLogForeman: async (jobDetails) => {
    const res = await _protectedFetch(uri_create_new_job_log_foreman,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  createNewJobLogScheduler: async (jobDetails) => {
    const res = await _protectedFetch(uri_create_new_job_log_scheduler,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  markPoWPhotoDeleted: async (photoDetails) => {
    const res = await _protectedFetch(uri_mark_pow_photo_deleted,
      {
        method: 'POST',
        body: JSON.stringify(photoDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  createCostBreakdown: async (breakdownData, submit = false) => {
    breakdownData.submit = submit

    const res = await _protectedFetch(uri_create_cost_breakdown,
      {
        method: 'POST',
        body: JSON.stringify(breakdownData),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  updateCostBreakdown: async (updateData, submit = false) => {
    updateData.submit = submit

    const res = await _protectedFetch(uri_update_cost_breakdown,
      {
        method: 'POST',
        body: JSON.stringify(updateData),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  markCostBreakdownDeleted: async (costBreakdownId) => {
    const res = await _protectedFetch(uri_mark_cost_breakdown_deleted,
      {
        method: 'POST',
        body: JSON.stringify({ costBreakdownId: costBreakdownId }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  unlockCostBreakdown: async (costBreakdownId) => {
    const res = await _protectedFetch(uri_unlock_cost_breakdown,
      {
        method: 'POST',
        body: JSON.stringify({ costBreakdownId: costBreakdownId }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    const data = await res.json()
    return data
  },
  updateEstimate: async (id, field, value) => {
    const res = await _protectedFetch(uri_update_estimate,
      {
        method: 'POST',
        body: JSON.stringify({
          id,
          field,
          value
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  createEmployee: async (employee) => {
    const res = await _protectedFetch(uri_create_employee,
      {
        method: 'POST',
        body: JSON.stringify({
          name: employee.name,
          email: employee.email,
          role: employee.role,
          locationId: employee.locationId,
          crewId: employee.crewId,
          guniteRate: employee.guniteRate,
          plasterRate: employee.plasterRate,
          quartzRate: employee.quartzRate,
          pebbleRate: employee.pebbleRate,
          driverRate: employee.driverRate,
          phone: employee.phone
        }),

        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    return res
  },
  getAllCustomers: async () => {
    const res = await _protectedFetch(uri_get_all_customers,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  updateEmployee: async (employee) => {
    const res = await _protectedFetch(uri_update_employee,
      {
        method: 'POST',
        body: JSON.stringify({
          id: employee.id,
          name: employee.name,
          email: employee.email,
          role: employee.role,
          locationId: employee.locationId,
          crewId: employee.crewId,
          guniteRate: employee.guniteRate,
          plasterRate: employee.plasterRate,
          quartzRate: employee.quartzRate,
          pebbleRate: employee.pebbleRate,
          driverRate: employee.driverRate,
          phone: employee.phone
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  deleteEmployee: async (employee) => {
    const res = await _protectedFetch(uri_delete_employee,
      {
        method: 'POST',
        body: JSON.stringify({
          id: employee.id,
          role: employee.role,
          locationId: employee.locationId
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  createBlankJob: async (jobDetails) => {
    const tempDate = new Date();
    const timeStamp = tempDate.toLocaleTimeString().split(' ')
    const [hour, minute, second] = timeStamp[0].split(':')
    tempDate.setUTCHours((timeStamp[1] === 'PM' ? Number(hour) + 12 : Number(hour)), Number(minute), Number(second))

    jobDetails.requestedDate = tempDate

    const res = await _protectedFetch(uri_create_blank_job,
      {
        method: 'POST',
        body: JSON.stringify(jobDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  addNewCustomer: async (builderDetails) => {
    const res = await _protectedFetch(uri_add_new_customer,
      {
        method: 'POST',
        body: JSON.stringify(builderDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getBatchIngredients: async () => {
    const res = await _protectedFetch(uri_get_batch_ingredients,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    return data
  },
  setNewPin: async (hash, pin) => {
    const res = await _protectedFetch(uri_set_new_pin,
      {
        method: 'POST',
        body: JSON.stringify({
          hash,
          pin
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  },
  getForemanLoginLink: async (email) => {
    const payload = { email }
    const res = await _protectedFetch(uri_get_foreman_login_link,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const { hash } = await res.json()
    const link = `${window.location.protocol}//${window.location.host}/login-foreman?h=${hash}`
    return link
  },
  getPersonnelForAllLocations: async () => {
    const res = await _protectedFetch(uri_get_personnel_for_all_locations,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = res.json()
    return data
  },
  detectForemanStatus: async (hash) => {
    const payload = { hash }
    const res = await _protectedFetch(uri_detect_foreman_status,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const { data } = await res.json()
    return data
  },
  updateCrewVisibility: async (crewDetails) => {
    const res = await _protectedFetch(uri_update_crew_visibility,
      {
        method: 'POST',
        body: JSON.stringify(crewDetails),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await res.json()
    console.log(data)
    return data
  },
  uploadPDF: async (formData) => {
    const res = await _protectedFetch(uri_upload_PDF,
      {
        method: 'POST',
        body: formData
      }
    )
    return res
  }
}

const _getFullPath = (domain, uri) => {
  return `${domain}${uri}`
}

const _protectedFetch = async (uri, args = {}) => {
  const accessToken = cookies.get('at')
  const res = await fetch(uri, {
    ...args,
    headers: {
      ...args.headers,
      Authorization: `bearer ${accessToken}`,
    }
  })
  if (res.status === 401) {
    serviceMethods.clearAuthTokens()
    window.location.reload()
  }
  return res
}

export default { ...serviceMethods }

const uri_customers = _getFullPath(DOMAIN, '/customers')
const uri_pathway = _getFullPath(DOMAIN, '/pathway')
const uri_login = _getFullPath(DOMAIN, '/auth/login')
const uri_login_with_pin = _getFullPath(DOMAIN, '/auth/login-with-pin')
const uri_profile = _getFullPath(DOMAIN, '/auth/me')
const uri_create_gunite_job_3a = _getFullPath(DOMAIN, '/jobs/create-gunite')
const uri_create_plaster_job_3a = _getFullPath(DOMAIN, '/jobs/create-plaster')
const uri_get_location_specific_jobs = _getFullPath(DOMAIN, '/jobs/get-jobs-for-one-location')
const uri_get_all_jobs = _getFullPath(DOMAIN, '/jobs/get-all-jobs')
const uri_update_gunite_job_3a = _getFullPath(DOMAIN, '/jobs/update-gunite-job-3a')
const uri_update_plaster_job_3a = _getFullPath(DOMAIN, '/jobs/update-plaster-job-3a')
const uri_update_pools_plaster_3b = _getFullPath(DOMAIN, '/pools/update-pools-plaster-3b')
const uri_update_pools_gunite_3b = _getFullPath(DOMAIN, '/pools/update-pools-gunite-3b')
const uri_update_pool_plaster_choices = _getFullPath(DOMAIN, '/pools/update-pool-plaster-choices')
const uri_update_plaster = _getFullPath(DOMAIN, '/jobs/update-plaster')
const uri_update_supervisor = _getFullPath(DOMAIN, '/jobs/supervisor')
const uri_update_requested_date = _getFullPath(DOMAIN, '/jobs/update-requested-date')
const uri_get_disabled_dates = _getFullPath(DOMAIN, '/disabledDates/get-disabled-dates')
const uri_submit_job = _getFullPath(DOMAIN, '/jobs/submit-job')
const uri_upload_pool_plan = _getFullPath(DOMAIN, '/pools/upload-pool-plan')
const uri_update_pool_plan = _getFullPath(DOMAIN, '/pools/update-pool-plan')
const uri_upload_location_file = _getFullPath(DOMAIN, '/location-files/upload-location-file')
const uri_delete_location_files = _getFullPath(DOMAIN, '/location-files/delete-location-files')
const uri_delete_pool_and_job_by_id = _getFullPath(DOMAIN, '/delete/delete-pool-and-job-by-id')
const uri_update_job_and_pool = _getFullPath(DOMAIN, '/jobs/update-job-and-pool')
const uri_update_location = _getFullPath(DOMAIN, '/customers/updateLocation')
const uri_create_location = _getFullPath(DOMAIN, '/locations/create-admin-branch-location')
const uri_update_job_location = _getFullPath(DOMAIN, '/jobs/update-job-location')
const uri_get_locations = _getFullPath(DOMAIN, '/locations/get-locations')
const uri_get_locations_for_map = _getFullPath(DOMAIN, '/locations/get-map-locations')
const uri_get_location_details = _getFullPath(DOMAIN, '/locations/get-location-details')
const uri_delete_locations = _getFullPath(DOMAIN, '/locations/delete-locations')
const uri_update_admin_branch_location_details = _getFullPath(DOMAIN, '/locations/update-admin-branch-location-details')
const uri_update_customer_account_info = _getFullPath(DOMAIN, '/customers/updateCustomerInfoAccount')
const uri_request_password_reset_email = _getFullPath(DOMAIN, '/auth/forgot-password')
const uri_update_password = _getFullPath(DOMAIN, '/auth/reset-password')
const uri_verify_token = _getFullPath(DOMAIN, '/auth/verify-token')
const uri_create_calendar_job_from_cal = _getFullPath(DOMAIN, '/calendar-jobs/create-calendar-job-from-cal')
const uri_update_job_from_cal = _getFullPath(DOMAIN, '/jobs/update-job-from-cal')
const uri_update_date = _getFullPath(DOMAIN, '/jobs/update-date')
const uri_unarchive = _getFullPath(DOMAIN, '/jobs/unarchive')
const uri_mark_calendar_job_deleted = _getFullPath(DOMAIN, '/calendar-jobs/mark-calendar-job-deleted')
const uri_update_calendar_job = _getFullPath(DOMAIN, '/calendar-jobs/update-calendar-job')
const uri_create_calendar_job_from_mini_cal = _getFullPath(DOMAIN, '/calendar-jobs/create-calendar-job-from-mini-cal')
const uri_reschedule_calendar_job_from_mini_cal = _getFullPath(DOMAIN, '/calendar-jobs/reschedule-calendar-job-from-mini-cal')
const uri_get_materials = _getFullPath(DOMAIN, '/materials/get-materials')
const uri_add_materials_subcategory_item = _getFullPath(DOMAIN, '/materials/add-materials-subcategory-item')
const uri_update_materials_subcategory_item = _getFullPath(DOMAIN, '/materials/update-materials-subcategory-item')
const uri_upsert_location_materials_subcategory_item = _getFullPath(DOMAIN, '/location-materials/upsert-subcategory-item')
const uri_upsert_checkbox_change = _getFullPath(DOMAIN, '/location-materials/upsert-checkbox-change')
const uri_delete_materials_subcategory_items = _getFullPath(DOMAIN, '/materials/delete-materials-subcategory-items')
const uri_get_location_materials = _getFullPath(DOMAIN, '/location-materials')
const uri_read_rate = _getFullPath(DOMAIN, '/rate/read-rate')
const uri_read_location_rate = _getFullPath(DOMAIN, '/location-rate/read-location-rate')
const uri_create_rate = _getFullPath(DOMAIN, '/rate/create-rate')
const uri_create_location_rate = _getFullPath(DOMAIN, '/location-rate/create-location-rate')
const uri_get_job_notes = _getFullPath(DOMAIN, '/job-notes/get-job-notes')
const uri_pin_or_unpin_note = _getFullPath(DOMAIN, '/job-notes/pin-or-unpin-note')
const uri_create_job_note = _getFullPath(DOMAIN, '/job-notes/create-job-note')
const uri_update_job_note = _getFullPath(DOMAIN, '/job-notes/update-job-note')
const uri_delete_job_note = _getFullPath(DOMAIN, '/job-notes/delete-job-note')
const uri_create_flashing_job = _getFullPath(DOMAIN, '/calendar-jobs/create-flashing-job')
const uri_schedule_flash_job_on_cal = _getFullPath(DOMAIN, '/calendar-jobs/schedule-fj-on-cal')
const uri_unschedule_flash_job_from_cal = _getFullPath(DOMAIN, '/calendar-jobs/unschedule-fj-from-cal')
const uri_reschedule_flash_job_on_cal = _getFullPath(DOMAIN, '/calendar-jobs/reschedule-fj-on-cal')
const uri_add_activity_log = _getFullPath(DOMAIN, '/activity-log/add-activity-log')
const uri_upload_photos_to_job_log = _getFullPath(DOMAIN, '/job-log/upload-photos-to-job-log')
const uri_submit_job_log_foreman = _getFullPath(DOMAIN, '/job-log/submit-job-log-foreman')
const uri_submit_job_log_scheduler = _getFullPath(DOMAIN, '/job-log/submit-job-log-scheduler')
const uri_create_new_job_log_foreman = _getFullPath(DOMAIN, '/job-log/create-new-job-log-foreman')
const uri_create_new_job_log_scheduler = _getFullPath(DOMAIN, '/job-log/create-new-job-log-scheduler')
const uri_mark_pow_photo_deleted = _getFullPath(DOMAIN, '/job-file/mark-pow-photo-deleted')
const uri_get_all_admins = _getFullPath(DOMAIN, '/admin/get-all-admins')
const uri_create_new_admin = _getFullPath(DOMAIN, '/admin/create-admin')
const uri_edit_admin = _getFullPath(DOMAIN, '/admin/edit-admin')
const uri_delete_admin = _getFullPath(DOMAIN, '/admin/delete-admin')
const uri_get_all_branch_admins = _getFullPath(DOMAIN, '/admin/get-all-branch-admins')
const uri_create_new_branch_admin = _getFullPath(DOMAIN, '/admin/create-branch-admin')
const uri_edit_branch_admin = _getFullPath(DOMAIN, '/admin/edit-branch-admin')
const uri_delete_branch_admin = _getFullPath(DOMAIN, '/admin/delete-branch-admin')
const uri_create_cost_breakdown = _getFullPath(DOMAIN, '/cost-breakdown/create-cost-breakdown')
const uri_update_cost_breakdown = _getFullPath(DOMAIN, '/cost-breakdown/update-cost-breakdown')
const uri_mark_cost_breakdown_deleted = _getFullPath(DOMAIN, '/cost-breakdown/mark-cost-breakdown-deleted')
const uri_unlock_cost_breakdown = _getFullPath(DOMAIN, '/cost-breakdown/unlock-cost-breakdown')
const uri_update_estimate = _getFullPath(DOMAIN, '/jobs/update-estimate')
const uri_create_employee = _getFullPath(DOMAIN, '/employee/create-employee')
const uri_update_employee = _getFullPath(DOMAIN, '/employee/update-employee')
const uri_delete_employee = _getFullPath(DOMAIN, '/employee/delete-employee')
const uri_get_all_customers = _getFullPath(DOMAIN, '/customers/get-all-customers')
const uri_create_blank_job = _getFullPath(DOMAIN, '/jobs/create-blank-job')
const uri_get_batch_ingredients = _getFullPath(DOMAIN, '/batch-ingredients/get-batch-ingredients')
const uri_add_new_customer = _getFullPath(DOMAIN, '/customers/add-new-customer')
const uri_set_new_pin = _getFullPath(DOMAIN, '/auth/set-new-pin')
const uri_get_foreman_login_link = _getFullPath(DOMAIN, '/auth/get-foreman-login-link')
const uri_get_personnel_for_all_locations = _getFullPath(DOMAIN, '/locations/get-personnel-for-all-locations')
const uri_detect_foreman_status = _getFullPath(DOMAIN, '/auth/detect-foreman-status')
const uri_update_crew_visibility = _getFullPath(DOMAIN, '/crews/update-crew-visibility')
const uri_upload_PDF = _getFullPath(DOMAIN, '/onedrive/upload-pdf')